<template >
    <div id="content_sol">
       
        <div class="solicitud" v-if="auth==true" >
               <img class="logosheader" src="@/assets/ticket/logos.svg" alt="logos Laboratorio Barragan">

               <p class="box1">PACIENTE</p>
               <p class="paciente_name">{{soldata.nombre}}</p>

               <div class="grupsol dflx">
                    <div class="gs1">
                        <p class="hd">SEXO</p>
                        <p class="bd" >{{soldata.sexo || '---'}}</p>
                    </div>
                    <div class="gs2">
                        <p class="hd">EDAD</p>
                        <p class="bd"> {{ soldata.edad !='undefined' &&  soldata.edad  ?  soldata.edad +' AÑOS'  : '---'}} </p>
                    </div>
                    <div class="gs3">
                        <p class="hd">FECH. NAC.</p>
                        <p class="bd">{{ soldata.fecha_nacimiento !='undefined' && soldata.fecha_nacimiento  ?  soldata.fecha_nacimiento : '---'}}</p>
                    </div>
               </div>


                <p class="box2">{{soldata.clave }}</p>
                <div class="gs4 dflx">
                        <p class="hd2">FECH. RECEPCIÓN</p>
                        <p class="bd2" >{{soldata.fecha }}</p>
                </div>
                 <div class="gs5 dflx">
                        <p class="hd2">FECH. EMISIÓN</p>
                        <p class="bd2" >{{soldata.muestras[0].fecha_l | date_v2 }}</p> <!-- fecha del ultima cambio-->
                </div>


                <!-- {{soldata.obv || '---'}} poner lo que viene en extra. -->

                   <div v-for="muestra in soldata.muestras" :key="muestra.id">
                         <p class="box1">OBSERVACIONES</p>
                         <p class="obsr">{{muestra.extra.obv}}</p>
                      <div v-for="perfil in muestra.perfiles" :key="perfil.id">
                            <div class="analisis-box">
                                     <p class="box3">ANÁLISIS</p>
                            <div class="box4"> <!-- nombre del perfil-->
                                <p>{{perfil.name}}</p>
                                <img v-if="perfil.updated_at != 'no'" @click="perfil.updated_at = 'no'" src="@/assets/ticket/up.png" alt="">
                                <img v-if="perfil.updated_at == 'no'" @click="perfil.updated_at = 'si'" src="@/assets/ticket/dw.png" alt="">
                            </div>
                            <div v-if="perfil.updated_at !='no'">
                                 <div v-for="resultado in perfil.resultados" :key="resultado.id">
                                <div class="prueba-info">
                                <div class="dflx pig">
                                    <p>PRUEBA</p>
                                    <p>{{resultado.pname}}</p>
                                </div>
                                <div class="dflx pig">
                                    <p>RESULTADO</p>
                                    <p class="fw">{{resultado.resultado || '---'}}</p>
                                </div>
                                <div class="dflx pig">
                                    <p>UNIDADES</p>
                                    <p class="fw">{{resultado.uni || '---'}}</p>
                                </div>
                                <div class="dflx pig">
                                    <p>REFERENCIA</p>
                                    <p> {{resultado.ref || '---'}}
                                    </p>
                                </div>
                            </div>
                            <img class="lineas" src="@/assets/ticket/lineas.svg" alt="">
                            </div>
                             <div class="perfil-foo">
                                <p>Método: {{perfil.metodo}}</p>
                                <p>Tipo de Muestra: {{perfil.tipo_muestra}}</p>
                                <p>Toma de Muestra: {{muestra.created_at | date}}</p>
                            </div>
                            </div>
                            
                           
                            </div>
            </div>

            </div>

               



                <div class="dflx cnt">
                    <img src="@/assets/ticket/firma.svg" alt="Firma">
                </div>
           <!--  <div class="dflx cnt">
                  <button class="oculto-impresion" @click="imprimir()">IMPRIMIR</button>
             </div> -->
            
        </div>
       
        
        
        <!--<p>{{auth}}</p> -->
        <div class="formcode" v-if="auth==false">
            <div class="logo">
                 <img src="@/assets/ticket/barraganm.png" alt="logo Laboratorio Barragan">
            </div>
             <form   @submit.prevent="buscar()">
             <h2 class="titlesol">Solicitud</h2>
            <label for="inputcode">Ingresa el código de tu ticket</label>
                <input class="inputcode" v-model="code" type="text" name="inputcode" :placeholder="'hT7yhKLd'" required>
                <button type="submit" >ACEPTAR</button>
            <p class="codigo-error" v-if="status=='error'">{{message}}</p>
       
             </form>
        </div>
       
        
        
    </div>
</template>
<script>


import {mapActions} from 'vuex';
import dayjs from 'dayjs'
//pJm_L8Z-NZs
export default {
    data(){
        return{
            auth:false,
            code:'',
            status:'',
            message:''
        }
    },
    async created(){
        if(!this.$route.query.id){ 
           this.$router.push("/").catch(err => {});
        }
        
    },

    computed:{
        soldata() {
            let data = this.$store.getters["solserv/getAdded"]

            return data
        }
    },
    methods:{
          ...mapActions('solserv', ['setAddedSrv']),
        buscar : async function(){
                  const id =this.$route.query.id;
                 let result = await this.$store.dispatch("solserv/addItemSrv",  {option:'servicios_b/ticket', item: {id:id, code:this.code}});
                 console.log(result)
                 if(result.status !='success'){
                    this.status='error'
                    this.message='Código no valido.'
                    this.auth=false
                    this.delStatus()
                    return null
                 }else{
                    this.setAddedSrv(result.result)
                    this.auth=true
                     this.status='success'
                 }


                 
        },
          imprimir: function  () {
            window.print();
         },
        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
            setTimeout(() => this.wait()
            ,500);
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
        },
    },
    filters:{
        date(fecha){
            if(!fecha || fecha =='undefined') return '---'
           
            return dayjs(fecha).format('DD/MM/YY hh:mm')
        },
         date_v2(fecha){
            if(!fecha || fecha =='undefined') return '---'
           
            return dayjs(fecha).format('YYYY-MM-DD')
        }
    }
}
</script>
<style >
   

    .solicitud{
        margin-top: 10.933333333333334VW;
        margin-left: 10.666666666666668VW;
        width: 78.93333333333334VW;
        margin-bottom:10.933333333333334VW;
    }   
    img.logosheader{
        margin-bottom: 9.066666666666666VW;
        width: 78.93333333333334VW;
        height: 33.86666666666667VW;
        font-weight: 700;
        font-size: 3.4666666666666663VW;
    }
.cnt{
     justify-content: center; 
         align-items: center;
}
    p.box1{
        background: rgba(54, 57, 143, 1);
        color: white;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 2.568VW;
        padding-top: 2.568VW;
        border-radius: 16VW 16VW 0px 0px;
        margin: 0vw;
         font-size: 3.4666666666666663VW;
         font-weight: 700;
    }
     p.box3{
        background: rgba(54, 57, 143, 1);
        color: white;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 2.568VW;
        padding-top: 2.568VW;
        border-radius: 16VW 16VW 0px 0px;
        margin: 0vw;
          margin-bottom: 0.26666666666666666VW;
         font-size: 3.4666666666666663VW;
         font-weight: 400;
         
    }
    .analisis-box{
margin-bottom: 5.866666666666666VW;
    }
     .box4{
        background: rgba(54, 57, 143, 1);
          margin-top: 0.26666666666666666VW;
        padding: 2.568VW 4.8VW;
        margin: 0vw;
        display: flex;
    }
    .box4 p{
        margin: 0vw;
         color: white;
        text-transform: uppercase;
         font-size: 3.4666666666666663VW;
         font-weight: 700;
         width: 90%;
    }
    .box4 img{
        width: 6.67VW;
        height: 2.67VW;
        padding-top: .5vw;
    }
    p.paciente_name{
        background: rgba(248, 248, 248, 1);
        padding-top: 2.096VW;
        padding-bottom: 2.096VW;
        margin-top: 0.6773333333333333VW;
        margin-bottom: 0vw;
        text-align: center;
        font-size: 3.4666666666666663VW;
        font-weight: 700;
        color:rgba(86, 86, 86, 1);
    }

    p.hd{
        background: rgba(54, 57, 143, 1);
        color: white;
        font-weight: 700;
        font-size: 3.4666666666666663VW;
        border-left: 0.27VW solid white;
        text-align: center;
        padding-top: 2.5946666666666665VW;
        padding-bottom: 2.5946666666666665VW;
        margin-bottom: 0vw;
    }
    p.bd{
         text-align: center;
        font-size: 3.4666666666666663VW;
        font-weight: 700;
        color:rgba(86, 86, 86, 1);
         border-left: 0.27VW solid white;
        background: rgba(248, 248, 248, 1);
        margin-top: 0.6773333333333333VW;
        margin-bottom: 0vw;
        padding-top: 1.4773333333333332VW;
        padding-bottom: 1.4773333333333332VW;
    }
    .grupsol{
        margin-top: 0.6773333333333333VW;
    }
     .gs1 p.hd{
        border-left: none;
     }
       .gs1 p.bd{
        border-left: none;
        border-radius: 0px 0px 0px 16VW;
     }
       .gs3 p.bd{
        border-left: none;
        border-radius: 0px 0px 16VW 0px ;
     }
    .gs1{
        width: 25.7519VW;
    }
    .gs2{
        width: 22.112VW;
    }
    .gs3{
        width: 31VW;
    }

    p.box2{
        background: rgba(134, 134, 134, 1);
        color: white;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 2.568VW;
        padding-top: 2.568VW;
        border-radius: 16VW 16VW 0px 0px;
        margin: 0vw;
        font-weight: 700;
        font-size: 3.4666666666666663VW;
        margin-top: 2.1333333333333333VW;
    }

    p.hd2{
        background: rgba(134, 134, 134, 1);
        margin: 0vw;
        width: 43.46666666666666VW;
         padding-bottom: 2.568VW;
          color: white;
        padding-top: 2.568VW;
    }
    
       p.bd2{
          margin: 0vw;
        background: rgba(248, 248, 248, 1);
        width:35.8vW ;
         padding-bottom: 2.568VW;
        padding-top: 2.568VW;
        color: rgba(86, 86, 86, 1);
       
    }
    .gs4 p:first-child , .gs5 p:first-child{
        border-right: 0.26666666666666666VW solid white;
    }
    .gs4, .gs5{
        text-align: center;
        font-size: 3.4666666666666663VW;
        
        font-weight: 700;
        margin-top: 0.26666666666666666VW;
    }
    .gs5{
        margin-bottom:  2.1333333333333333VW;;
    }
    .gs5 p.bd2{
         border-radius: 0px 0px 16VW 0px ;
    }
      .gs5 p.hd2{
         border-radius: 0px 0px  0px 16VW ;
    }

    p.obsr{
        background: #F8F8F8;;
        color: rgba(86, 86, 86, 1);
        font-weight: 700;
        font-size: 3.4666666666666663VW;
        padding: 1.3333333333333335VW;
        margin-top: 0.26666666666666666VW;
        height: 18.666666666666668VW;
        overflow: auto;
    }

    .pig{
        background: rgba(248, 248, 248, 1);
      margin-top: 0.26666666666666666VW;
      padding-top: 2.933333333333333VW;
    }

    .pig p:last-child{
        font-size: 3.4666666666666663VW;
        font-weight: 400;
        width: 57.86666666666667VW;
        color: rgba(86, 86, 86, 1);
    }

    .pig p:first-child{
        font-size: 2.933333333333333VW;
        font-weight: 700;
        color: rgba(54, 57, 143, 1);
        text-align: center;
        width: 21.066666666666666VW;
    }

    p.fw{
        font-weight: 700 !important;
    }
    .perfil-foo p{
        margin: 0vw;
        font-size: 3.4666666666666663VW;
        color: rgba(86, 86, 86, 1);
    }

    img.lineas{
        width: 100%;
    }


    /* login */
    
    

    .formcode{
        width: 68.8VW;
        margin-top: 13.066666666666665VW;
        margin-left: 15.733333333333333VW;
        
    }
    .logo{
        display: flex;
         justify-content: center;
       
        align-items: center;
    }
    .logo img{
        width: 37.6VW;
        height: 37.6VW;
        margin-bottom: 10.933333333333334VW;
       
    }
    h2.titlesol{
        width: 36.8VW;
        color:rgba(121, 46, 24, 1) ;
        font-size: 9.4VW;
        font-weight: 700;
        margin-bottom: 0vw;
    }
    .formcode label{
        font-size: 5.333333333333334VW;
        margin-bottom: 9.6VW;
        font-weight: 400;
        color: rgba(121, 46, 24, 1);
        margin-top: 0vw;
    }

    .formcode input{
        border: 0.26666666666666666VW  solid rgba(211, 211, 211, 1);
        width: 100%;
        margin-bottom: 3.4666666666666663VW;
        font-size: 5.6000000000000005VW;
        color: rgba(54, 57, 143, 1);
        font-weight: 700;
        padding-top: 6.666666666666667VW;
        padding-left: 6.133333333333333VW;
        padding-right: 6.133333333333333VW;
        padding-bottom: 6.666666666666667VW;
    }
    .formcode input::placeholder{
       color: rgba(211, 211, 211, 1);
       font-weight: 400;
       font-style: italic;
    }

    .formcode button{
        background: rgba(54, 57, 143, 1);
        color: white;
        height: 19.733333333333334VW;
        width: 100%;
        font-weight: 700;
        font-size: 4.266666666666667VW;
        border: none;
    }

    p.codigo-error{
        margin-top: 5vw;
        background: rgb(214, 53, 53);
        color: white;
        text-align: center;
        text-transform: uppercase;
        padding: 1vw;
    }

    .cnt img{
        width: 51.46666666666667VW;
        height: 16.8VW;
        margin-top: 9.066666666666666VW;
    }

    button.oculto-impresion{
        width: 58.93333333333334VW;
        background: #009084;
        border: none;
        color: white;
        font-weight: 700;
        font-size: 4.266666666666667VW;
        height: 11.466666666666667VW;
        border-radius: 16VW;
        margin-top: 9.6VW;
        margin-bottom: 9.6VW;
    }
    


     @media print {
    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
    }
   }
</style>